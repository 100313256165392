import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css';
import GC from '@grapecity/spread-sheets';
import useExportExcel from '@/hooks/Reporting/useExportExcel';
import { onMounted, onBeforeUnmount } from 'vue';
// import { data } from './fakeData';

const useOverallCombinabilityMatrix = ({
  fixedColumnNum,
  columnTitle,
  tips,
  getContentData
}: {
  fixedColumnNum: number;
  columnTitle: string;
  tips: string;
  getContentData: () => Promise<any>;
}) => {
  const MAX_HEADER_ROW = 3;
  const INDENT_COLUMN = 1;
  const FIXED_COLUMN_NUM = fixedColumnNum;
  const FONT_STYLE = '500 12px CorpoS';
  const spreadNS = GC.Spread.Sheets;
  let spread: GC.Spread.Sheets.Workbook;
  let sheet: GC.Spread.Sheets.Worksheet;
  let allColumnsLength: number;

  /**
   * 生成表头的层级-level， 从0开始，这样就能得到该表头需要跨越的行数；
   * rowCount：有children就为1， 没有就是 5-level；
   * @param headers 原始表头对象数组
   * @param level 表头的层级
   * @returns
   */
  function generateLevel(headers: any, level = 1) {
    headers.forEach((header: any) => {
      header.level = level;
      if (header.children) {
        generateLevel(header.children, level + 1);
        header.rowCount = 1;
      } else {
        header.rowCount = MAX_HEADER_ROW - level;
      }
      return header;
    });
  }

  // todo 递归逻辑有待优化
  /**
   * 获取叶子节点数量
   * @param item
   * @returns
   */
  function getChildrenNum(item: any) {
    if (!item.children) {
      return 1;
    }
    let count = 0;
    item.children.forEach((element: any) => {
      count += getChildrenNum(element);
    });
    return count;
  }
  /**
   *  获取每个 header 下的子节点数量，也就是跨度 columnCount
   * @param headers
   * @returns
   */
  function getHeaderCover(headers: any) {
    return headers.forEach((header: any) => {
      if (!header.children) {
        header.columnCount = 1;
      } else {
        header.columnCount = getChildrenNum(header);
        getHeaderCover(header.children);
      }
      return header;
    });
  }

  /**
   * 获得没有children的表头数量，也就是表格列数, 作为绑定数据的 bindColumns
   * @param headers
   * @returns
   */
  function getColumnsWithoutChildren(headers: any) {
    return headers.reduce((list: any, header: any) => {
      if (!header.children) return [...list, header];
      return [...list, ...getColumnsWithoutChildren(header.children)];
    }, []);
  }

  let levelColumn = {
    1: 0,
    2: 0
    // 2: 0,
    // 3: 0,
    // 4: 0
  };

  /**
   * 列头单元格背景颜色设置
   * @param displayName
   * @param level
   * @param hasChildren
   * @returns
   */
  function generateBackColor(displayName: string, level: number, startColumn: number) {
    if (level === 2 && startColumn < FIXED_COLUMN_NUM) return '#FFE699';
    if (
      level === 2 &&
      startColumn >= FIXED_COLUMN_NUM &&
      (displayName.includes('Program Category') || displayName.includes('项目类型'))
    )
      return '#FFE699';
    return '#FCF2D1';
  }

  /**
   * 对每一个列头对象，生成对应的列头
   * @param headerObj
   * @param startRow
   * @param startColumn
   */
  function generateSingleHeader(headerObj: any, startRow: number, startColumn: number) {
    const { displayName, rowCount, columnCount, level, children } = headerObj;
    const endColumn = columnCount + startColumn;
    levelColumn[level] = endColumn;
    if (!children) {
      for (let i = level; i < MAX_HEADER_ROW; i++) {
        levelColumn[i] = endColumn;
      }
    }
    headerObj.startColumn = startColumn;
    // 划取列头单元格范围
    sheet.addSpan(startRow, startColumn, rowCount, columnCount, spreadNS.SheetArea.colHeader);
    const range = sheet.getRange(startRow, startColumn, rowCount, columnCount, GC.Spread.Sheets.SheetArea.colHeader);
    // 设置可以换行
    range.wordWrap(true);
    // 设置列头单元格边框样式
    const headerLineBorder = new spreadNS.LineBorder('#C0C6CC', spreadNS.LineStyle.thin);
    range.setBorder(headerLineBorder, { all: true });
    // 设置列头单元格背景颜色
    range.backColor(generateBackColor(displayName, level, startColumn));
    // 设置列头单元格内边距
    if (level === 2) {
      range.cellPadding('10 10 10 10');
    } else {
      range.cellPadding('10 0 10 0');
    }
    // 设置列头单元格字体
    range.font(FONT_STYLE);
    // 设置列头单元格内容
    sheet.setValue(startRow, startColumn, displayName, spreadNS.SheetArea.colHeader);
    // 列头宽度自适应
    // sheet.autoFitColumn(startColumn);
  }

  /**
   * 遍历列头对象及其子对象
   * @param headers
   */
  function genHeaders(headers: any) {
    for (let r = 0; r < headers.length; r++) {
      const current = headers[r];
      generateSingleHeader(current, current.level, levelColumn[current.level]);
      if (current.children) {
        genHeaders(current.children);
      }
    }
  }

  /**
   * 根据列头是否课收缩以及层级、startColumn、columnCount 计算收缩的列数
   * @param headers
   */
  function generateColumnGroup(headers: any) {
    headers.forEach((header: any) => {
      if (header.collapsed) {
        // let index: number;
        // let count: number;
        //! 调整列缩进方式的位置1
        const childFirst = header.children[0];
        const detColumnCount = childFirst.columnCount;
        const index = header.startColumn + detColumnCount;
        const count = header.columnCount - detColumnCount;
        // const childLast = header.children[header.children.length - 1];
        // const detColumnCount = childLast.columnCount;
        // const index = header.startColumn;
        // const count = header.columnCount - detColumnCount;
        // }
        // 列分组
        sheet.columnOutlines.group(index, count);
      }
      if (header.children) {
        generateColumnGroup(header.children);
      }
    });
  }

  /**
   * 设置内容单元格的格式和字体
   * @param bindColumns
   */
  function setViewPointFormat(bindColumns: any) {
    bindColumns.forEach((col: any) => {
      let formatter = '#,##0.00';
      const currentCol = sheet.getRange(-1, col.startColumn, -1, 1);
      if (['BU'].includes(col.displayName)) {
        formatter = 'General';
        currentCol.width(60);
      }
      if (['Period'].includes(col.displayName)) {
        formatter = 'General';
        currentCol.width(120);
      }
      if (col.displayName.includes('%')) {
        formatter = '0.00%';
      }
      currentCol.formatter(formatter);
      currentCol.font(FONT_STYLE);
    });
  }

  /**
   *  处理列头对象
   * @param headers
   */
  function generateReportingHeaders(headers: any) {
    levelColumn = {
      1: 0,
      2: 0
      // 2: 0,
      // 3: 0,
      // 4: 0
    };
    generateLevel(headers);
    getHeaderCover(headers);
    genHeaders(headers);
    generateColumnGroup(headers);
  }

  function initSpread(headers: any) {
    // 得到用来绑定数据的列对象
    const bindColumns = getColumnsWithoutChildren(headers);
    const columnsCount = bindColumns.length;
    allColumnsLength = columnsCount;

    sheet.suspendPaint();
    sheet.setRowCount(MAX_HEADER_ROW, spreadNS.SheetArea.colHeader);
    sheet.setRowCount(30, spreadNS.SheetArea.viewport);
    // 需要足够的列
    sheet.setColumnCount(columnsCount + 30, spreadNS.SheetArea.colHeader);

    // 是否显示行头
    // sheet.options.rowHeaderVisible = false;
    // 表单保护
    sheet.options.isProtected = true;
    // 允许行列折叠
    sheet.options.protectionOptions = {
      allowOutlineRows: true,
      allowOutlineColumns: true
    };
    // 是否显示网格线
    // sheet.options.gridline = { color: '#C0C6CC', showVerticalGridline: true, showHorizontalGridline: true };
    spread.options.autoFitType = GC.Spread.Sheets.AutoFitType.cell;
    // sheet是否显示
    spread.options.tabNavigationVisible = false;
    spread.options.tabStripVisible = false;
    // 默认列宽
    sheet.defaults.colWidth = 80;
    // 默认行高
    sheet.defaults.rowHeight = 30;
    const style = sheet.getDefaultStyle();
    style.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    style.borderBottom = new GC.Spread.Sheets.LineBorder('#C0C6CC', GC.Spread.Sheets.LineStyle.thin);
    style.borderTop = new GC.Spread.Sheets.LineBorder('#C0C6CC', GC.Spread.Sheets.LineStyle.thin);
    style.borderLeft = new GC.Spread.Sheets.LineBorder('#C0C6CC', GC.Spread.Sheets.LineStyle.thin);
    style.borderRight = new GC.Spread.Sheets.LineBorder('#C0C6CC', GC.Spread.Sheets.LineStyle.thin);

    generateReportingHeaders(headers);

    sheet.autoGenerateColumns = false;

    bindColumns.forEach((col: any) => {
      col.name = col.name.toString();
    });
    sheet.bindColumns(bindColumns);

    setViewPointFormat(bindColumns);
    // 列折叠
    for (let column = 0; column < bindColumns.length; column++) {
      sheet.columnOutlines.setCollapsed(column, false);
    }
    sheet.addSpan(0, 0, 1, columnsCount, spreadNS.SheetArea.colHeader);
    sheet.setValue(0, 0, tips, spreadNS.SheetArea.colHeader);
    sheet.getCell(0, 0, spreadNS.SheetArea.colHeader).wordWrap(true);
    sheet
      .getRange(0, 0, 1, columnsCount, GC.Spread.Sheets.SheetArea.colHeader)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.left);
    if (columnsCount - FIXED_COLUMN_NUM !== 0) {
      sheet.addSpan(1, FIXED_COLUMN_NUM, 1, columnsCount - FIXED_COLUMN_NUM, spreadNS.SheetArea.colHeader);
      sheet.setValue(1, 2, columnTitle, spreadNS.SheetArea.colHeader);
    }
    //! 调整列缩进方式的位置2
    const direction = GC.Spread.Sheets.Outlines.OutlineDirection.backward;
    sheet.columnOutlines.direction(direction);

    // 表头做到自动换行并且自动增高
    spread.commandManager().execute({
      cmd: 'autoFitRow',
      sheetName: 'Sheet1',
      rows: [{ row: 0 }, { row: 1 }, { row: 2 }],
      columnHeader: true,
      autoFitType: GC.Spread.Sheets.AutoFitType.cellWithHeader
    });

    sheet.resumePaint();
  }

  function setReportingData(data: any) {
    sheet.suspendPaint();
    sheet.setDataSource(data);
    for (let row = 0; row < data.length; row++) {
      // 展开列缩进
      sheet.getCell(row, INDENT_COLUMN).textIndent(data[row].indent || 0);
    }
    sheet.outlineColumn.refresh();
    for (let row = 0; row < data.length; row++) {
      sheet.rowOutlines.setCollapsed(row, true);
    }

    // 得在有值之后设置列宽自适应
    const columns = Array.from({ length: FIXED_COLUMN_NUM }).map((_, index) => ({ col: index }));
    spread.commandManager().execute({
      cmd: 'autoFitColumn',
      sheetName: 'Sheet1',
      columns,
      isRowHeader: false,
      autoFitType: GC.Spread.Sheets.AutoFitType.cellWithHeader
    });

    // 空值 置灰
    const style2 = new spreadNS.Style();
    style2.backColor = '#BFBFBF';
    const cfs = sheet.conditionalFormats;
    cfs.addCellValueRule(spreadNS.ConditionalFormatting.ComparisonOperators.equalsTo, '', '', style2, [
      new spreadNS.Range(0, 0, data.length, allColumnsLength)
    ]);
    sheet.resumePaint();
  }

  function init(headers: any) {
    spread?.destroy();
    spread = new GC.Spread.Sheets.Workbook(document.getElementById('ss') as HTMLElement, { sheetCount: 1 });
    sheet = spread.getActiveSheet();
    initSpread(headers);
  }

  async function generateReporting() {
    // const { title, content } = data;
    const { title, content } = await getContentData();
    init(title);
    setReportingData(content);
  }

  onBeforeUnmount(() => {
    spread?.destroy();
  });

  const handleExport = () => {
    const json = JSON.stringify(spread.toJSON({ includeBindingSource: true }));
    const workbookClone = new GC.Spread.Sheets.Workbook();
    workbookClone.fromJSON(JSON.parse(json));
    const sheet = workbookClone.getActiveSheet();
    sheet.options.isProtected = false;
    useExportExcel(workbookClone, 'Application Overview Report');
  };
  return {
    handleExport,
    generateReporting
  };
};
export default useOverallCombinabilityMatrix;
